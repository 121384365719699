<template>
  <modal-window :showModal="showModalFlag">
    <h3>Wichtiger Hinweis</h3>
    <p>
      <span class="text-style-medium">Zählernummer: </span>
      {{ shortMeterInfo.meter_id }}
    </p>

    <p>
      Sie melden hiermit Ihren Zählerstand für
      <span class="text-style-medium">
        {{ getFormattedDate(readingAtDate) }}
      </span>
      mit:
    </p>

    <p class="consumption-text">
      {{ addDotToThousands(fieldsMR.meterValue.value) }} kWh
    </p>

    <p>
      Bitte überprüfen Sie noch einmal, dass dies korrekte Wert ist. <br />
      Diese Daten können später nicht mehr korrigiert werden.
    </p>

    <div class="modal-button-block">
      <button-regular @click="submitMeterReading" class="button-accent">
        Der Zählerstand ist korrekt
      </button-regular>

      <button-regular @click="showModal" class="button-cancel">
        Ich möchte den Zählerstand korrigieren
      </button-regular>
    </div>
  </modal-window>
</template>

<script>
import { fieldsMR } from '@/placeholder/fieldsValidation';
import { mapState } from 'vuex';
import ModalWindow from '@/components/next/modal-window.vue';
import ButtonRegular from '@/components/next/button-regular.vue';

const monthName = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember'
];

export default {
  components: { ModalWindow, ButtonRegular },

  props: ['showModal', 'readingAtDate', 'showModalFlag'],

  data: () => ({ fieldsMR }),

  computed: {
    ...mapState('user', ['shortMeterInfo'])
  },

  methods: {
    getFormattedDate(timestamp) {
      const date = new Date(timestamp);
      return (
        date.getDate() +
        '. ' +
        monthName[date.getMonth()] +
        ' ' +
        date.getFullYear()
      );
    },

    addDotToThousands(val) {
      if (val) {
        val = Math.round(Number(val.replace(/,/g, '.')));
        val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return val;
    },

    toNumber(val) {
      return Number(val.replace(/,/g, '.'));
    },

    submitMeterReading() {
      let data = {
        uuid: this.$route.params.uuid,
        reading_at: this.readingAtDate + ' 00:00:00',
        meter_value: this.toNumber(this.fieldsMR.meterValue.value)
      };
      this.$store.dispatch('user/METER_READING_SUBMIT', data).then(res => {
        if (res.status === 200) {
          this.$router.push({
            name: 'ThankYouMeterReading',
            params: { uuid: this.$route.params.uuid }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
