<template>
  <div>
    <div class="meter-reading_general-info smart-meter_info">
      <h4 class="color-accent-300">Verbleibende Strommenge</h4>

      <p class="color-accent-300">
        Zählernummer: {{ meterInfo.current_meter_id }}
      </p>

      <ul>
        <li>
          Verbleibende Strommenge in Ihrem aktuellen Paket:
          <span>{{ meterInfo.calculations.rest_kwh_quantity }} kWh</span>
        </li>

        <li>
          Basierend auf Ihrem bisherigen Verbrauch reicht Ihr aktuelles Paket
          <span
            :class="{
              'error-indicator': meterInfo.calculations.days_left <= 30
            }"
          >
            voraussichtlich
            {{ checkEmpty(meterInfo.calculations.days_left) }}
            Tage
          </span>
        </li>

        <li>
          Durchschnittlicher Verbrauch pro Tag
          <span>
            {{ checkEmpty(meterInfo.calculations.average_kwh_per_day) }}
            kWh
          </span>
        </li>

        <li>
          Gesamtverbrauch
          <span>
            {{ checkEmpty(meterInfo.calculations.overall_consumption) }}
            kWh
          </span>
        </li>
      </ul>
    </div>

    <div class="chart-packages_section smart-meter_chart-packages">
      <chart-packages
        :packagesData="meterInfo.packages"
        :smartmeter="meterInfo.smartmeter"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ChartPackages from '@/components/next/chart/chart-packages.vue';

export default {
  components: { ChartPackages },

  computed: {
    ...mapState('user', ['meterInfo'])
  },

  methods: {
    checkEmpty(val) {
      return val === null ? 'n/a' : val;
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
