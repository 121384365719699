<template>
  <div class="axis-container" :style="`height: ${containerHeight}px`">
    <div class="axis" :style="`height: ${containerHeight}px`">
      <div
        v-for="(_, index) in numberOfHorizontalLines"
        :key="index"
        class="lines"
      >
        <span class="y-label">
          {{ getYLabel(index) }}
          <br />
          kWh
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    containerHeight: { type: Number, required: true },
    yAxisHeight: { type: Number, required: true },
    numberOfHorizontalLines: { type: Number, required: true }
  },

  methods: {
    getYLabel(i) {
      return Math.floor(
        this.yAxisHeight - (this.yAxisHeight / this.numberOfHorizontalLines) * i
      );
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
