<template>
  <div class="chart-section smart-meter-chart">
    <div class="chart-update-info">
      <p class="color-accent-300">kWh</p>

      <p class="color-accent-300">
        Nächstes Datenupdate:
        {{ getNextUpdateMeterTime(meterInfo.smart_meter_readings.next_update) }}
      </p>
    </div>

    <div class="chart-block">
      <div class="chart-container">
        <axis-smartmeter
          :containerHeight="chartContainerHeight"
          :xAxisWidth="getXAxisWidth"
          :yAxisHeight="getYAxisHeight"
          :numberOfHorizontalLines="numberOfHorizontalLines"
          :chartContainerWidth="chartContainerWidth"
        />

        <div class="chart-wrap" :style="`height: ${chartContainerHeight}px`">
          <ul class="chart">
            <li v-for="(item, index) in mappedData" :key="index">
              <span
                :style="`height: ${getBarHeight(item.consumption)}%;`"
              ></span>
            </li>
          </ul>

          <div>
            <ul class="x-sm-label">
              <li class="desktop-view">
                <button
                  class="prev-year"
                  :class="{ 'hide-arrow': hideLeftArrow }"
                  type="button"
                  @click="displayPrevMonth"
                ></button>
              </li>

              <li class="left-label">
                01.{{ String(displayedMonth + 1).padStart(2, '0') }}
              </li>

              <li class="center-label">
                15.{{ String(displayedMonth + 1).padStart(2, '0') }}
              </li>

              <li class="right-label">
                {{ daysInMonth(displayedMonth) }}.{{
                  String(displayedMonth + 1).padStart(2, '0')
                }}
              </li>

              <li class="desktop-view">
                <button
                  class="next-year"
                  :class="{ 'hide-arrow': hideRightArrow }"
                  type="button"
                  @click="displayNextMonth"
                ></button>
              </li>
            </ul>

            <div class="mobile-view chart-button-container">
              <button
                class="prev-year"
                :class="{ 'hide-arrow': hideLeftArrow }"
                type="button"
                @click="displayPrevMonth"
              ></button>

              <button
                class="next-year"
                :class="{ 'hide-arrow': hideRightArrow }"
                type="button"
                @click="displayNextMonth"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxisSmartmeter from '@/components/next/chart-smartmeter/axis-sm.vue';
import { mapState } from 'vuex';

export default {
  components: { AxisSmartmeter },

  props: ['displayedMonth'],

  data() {
    return {
      chartContainerHeight: 730,
      groupedData: {}
    };
  },

  computed: {
    ...mapState('user', ['meterInfo']),

    mappedData() {
      const array = this.groupedData[this.displayedMonth] ?? [];
      const hasMissingDays =
        this.daysInMonth(this.displayedMonth) !== array.length;

      return hasMissingDays ? this.fillMissingDays(array) : array;
    },

    getYAxisHeight() {
      if (this.mappedData.length > 0) {
        const maxValue = Math.max(...this.mappedData.map(d => d.consumption));
        let val = Math.ceil(maxValue / 10) * 10;
        if (val - maxValue < 5) {
          val += 5;
        }
        return val;
      }
      return 100;
    },

    getXAxisWidth() {
      return this.mappedData.reduce((accumulator, object) => {
        return accumulator + object.numberOfDays;
      }, 0);
    },

    chartContainerWidth() {
      if (window.outerWidth < 768) {
        return 315 - this.daysInMonth(this.displayedMonth) * 9 - 25;
      }
      return 600 - this.daysInMonth(this.displayedMonth) * 17 - 40;
    },

    hideLeftArrow() {
      const firstMonth = Object.keys(this.groupedData).shift();

      return `${this.displayedMonth}` === firstMonth;
    },

    hideRightArrow() {
      const lastMonth = Object.keys(this.groupedData).pop();

      return `${this.displayedMonth}` === lastMonth;
    },

    numberOfHorizontalLines() {
      const yAxisHeight = this.getYAxisHeight;
      const divideBy = yAxisHeight <= 70 ? 5 : 10;

      return yAxisHeight / divideBy;
    }
  },

  methods: {
    fillMissingDays(array) {
      const missingDays = this.daysInMonth(this.displayedMonth) - array.length;
      const fillArray = Array(missingDays).fill({ consumption: 0 });

      return array.concat(fillArray);
    },

    getNextUpdateMeterTime(val) {
      var date = new Date(val * 1000);
      return (
        String(date.getHours()).padStart(2, '0') +
        ':' +
        String(date.getMinutes()).padStart(2, '0')
      );
    },
    getBarHeight(value) {
      return Math.floor((value * 100) / this.getYAxisHeight);
    },
    displayPrevMonth() {
      const nextMonth = this.displayedMonth - 1;

      if (this.groupedData[nextMonth]) {
        this.$emit('changeMonth', nextMonth);
      }
    },
    displayNextMonth() {
      const prevMonth = this.displayedMonth + 1;

      if (this.groupedData[prevMonth]) {
        this.$emit('changeMonth', prevMonth);
      }
    },

    groupDataByMonth() {
      this.meterInfo.smart_meter_readings.days?.forEach(reading => {
        const date = new Date(reading.date);
        const currentYear = new Date().getFullYear();

        if (currentYear !== date.getFullYear()) {
          return;
        }

        const month = date.getMonth();

        if (!this.groupedData[month]) {
          this.groupedData[month] = [];
        }

        this.groupedData[month].push(reading);
      });
    },

    daysInMonth(month) {
      const year = new Date();
      return new Date(year.getFullYear(), month + 1, 0).getDate();
    }
  },

  created() {
    if (window.outerWidth < 768) {
      this.chartContainerHeight = 600;
    }

    this.groupDataByMonth();
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
