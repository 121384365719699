<template>
  <div class="average-line" :style="`height: calc(${averageLineHeight}% - 2px)`">
    <div class="average-label">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    averageLineHeight: { type: Number, required: true }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
