<template>
  <div v-if="displayedMonth !== undefined" class="smart-meter_section">
    <h4 class="color-accent-300">Meinen Stromverbrauch</h4>

    <div class="smart-meter_month-reading">
      <p>Monatsverbrauch im {{ currentMonth }}</p>

      <div class="smart-meter_month-reading-wrap">
        <img src="~@/assets/images/images-next/sm-energy.svg" alt="energy" />

        <div>
          <p>{{ consumption }} kWh</p>
        </div>
      </div>
    </div>

    <div>
      <chart-smartmeter
        :displayedMonth="displayedMonth"
        @changeMonth="displayedMonth = $event"
      />
    </div>
  </div>
</template>

<script>
import ChartSmartmeter from '@/components/next/chart-smartmeter/chart-sm.vue';
import { mapState } from 'vuex';

const monthName = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember'
];

export default {
  components: { ChartSmartmeter },

  data() {
    return {
      displayedMonth: undefined,
      consumption: 0
    };
  },

  computed: {
    ...mapState('user', ['meterInfo']),

    currentMonth() {
      return monthName[this.displayedMonth];
    }
  },

  methods: {
    addDotToThousands(val) {
      if (val) {
        val = Math.round(Number(val.replace(/,/g, '.')));
        val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }

      return val ?? 0;
    },

    shouldShowPreviousMonth(today) {
      const days = this.meterInfo?.smart_meter_readings?.days ?? [];
      const lastRead = days[days.length - 1];
      const lastReadDate = lastRead ? new Date(lastRead.date) : undefined;

      return (
        (lastReadDate &&
          lastReadDate.getMonth() === today.getMonth() &&
          lastReadDate.getDate() < 10) ||
        today.getDate() < 10
      );
    },

    getDisplayedMonth() {
      const currentDate = new Date();
      const previousMonth = currentDate.getMonth() - 1;
      const currentMonth = currentDate.getMonth();

      if (previousMonth < 0) {
        return currentMonth;
      }

      return previousMonth >= 0 && this.shouldShowPreviousMonth(currentDate)
        ? previousMonth
        : currentMonth;
    }
  },
  watch: {
    displayedMonth: {
      async handler() {
        let consumption = this.meterInfo.calculations.current_month_consumption;

        if (this.displayedMonth !== new Date().getMonth()) {
          const year = new Date().getFullYear();
          const month = this.displayedMonth + 1;

          consumption = await this.$store.dispatch(
            'user/GET_MONTHLY_CONSUMPTION',
            {
              uuid: this.$route.params.uuid,
              month: `${year}-${month < 10 ? '0' + month : month}`
            }
          );
        }

        this.consumption = this.addDotToThousands(consumption);
      }
    }
  },
  mounted() {
    this.displayedMonth = this.getDisplayedMonth();
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
