<template>
  <div>
    <v-dialog
      ref="modal"
      v-model="modal"
      :close-on-content-click="false"
      :return-value.sync="reading"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedDateFormatted"
          readonly
          v-bind="attrs"
          v-on="on"
          class="date-picker-input tablet-view"
          required
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="reading"
        no-title
        scrollable
        :class="'date-picker-modal'"
        locale="de"
        :weekday-format="getWeekDay"
        :allowed-dates="allowedDates"
        :first-day-of-week="1"
        :min="min"
        :max="currentDate"
      >
        <v-spacer></v-spacer>
        <button-regular
          :class="'button-accent modal-button-cancel'"
          @click="modal = false"
          >Zurück</button-regular
        >
        <button-regular
          :class="'button-accent modal-button-submit'"
          @click="$refs.modal.save(reading)"
          >OK</button-regular
        >
      </v-date-picker>
    </v-dialog>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :close-on-click="false"
      :return-value.sync="reading"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedDateFormatted"
          readonly
          v-bind="attrs"
          v-on="on"
          class="date-picker-input only-desktop-view"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="reading"
        no-title
        scrollable
        :class="'date-picker-modal'"
        locale="de"
        :weekday-format="getWeekDay"
        :allowed-dates="allowedDates"
        :first-day-of-week="1"
        :min="min"
        :max="currentDate"
      >
        <v-spacer></v-spacer>
        <button-regular
          :class="'button-accent modal-button-cancel'"
          @click="approveDatePicker"
          >Zurück</button-regular
        >
        <button-regular
          :class="'button-accent modal-button-submit'"
          @click="$refs.menu.save(reading)"
          >OK</button-regular
        >
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import ButtonRegular from '@/components/next/button-regular.vue';

const daysOfWeek = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

export default {
  components: { ButtonRegular },
  
  data() {
    return {
      menu: false,
      modal: false,
      reading: null
    };
  },

  props: {
    value: {
      type: Boolean
    },
    min: {
      type: String
    }
  },

  computed: {
    currentDate() {
      let today = new Date();
      return (
        String(today.getFullYear()) +
        '-' +
        String(today.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(today.getDate()).padStart(2, '0')
      );
    },
    computedDateFormatted() {
      return this.formatDate(this.reading);
    }
  },

  methods: {
    getWeekDay(date) {
      let i = new Date(date).getDay();
      return daysOfWeek[i];
    },
    allowedDates(val) {
      return parseInt(val.split('-')[2], 10) % 1 === 0;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    approveDatePicker() {
      this.menu = false;
      this.$emit('menuActive', this.menu);
    }
  },

  watch: {
    reading() {
      this.$emit('choose-date', this.reading);
    },
    menu: function() {
      this.$emit('menuActive', this.menu);
    }
  }
};
</script>
<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
