<template>
  <div>
    <div class="chart-packages_container">
      <canvas id="myChart"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  props: {
    packagesData: {
      type: Array
    },
    smartmeter: {
      type: Boolean
    }
  },

  data() {
    return {
      label: [],
      consumptionData: [],
      consumptionDataStacked: [],
      chartHeight: 0,
      barWidth: 190
    };
  },

  mounted() {
    this.getChartData();

    let sizeTextInsideBar = 18;

    if (window.outerWidth < 1400) {
      this.barWidth = 120;
      Chart.defaults.font.size = 12;

      if (this.smartmeter) {
        this.barWidth = 80;
        sizeTextInsideBar = 10;
        Chart.defaults.font.size = 10;
      }

      if (this.packagesData.length > 3) {
        this.barWidth = 50;
        sizeTextInsideBar = 10;

        if (this.smartmeter) {
          this.barWidth = 35;
          sizeTextInsideBar = 8;
        }
      }
    } else {
      Chart.defaults.font.size = 14;
      sizeTextInsideBar = 21;

      if (this.smartmeter) {
        this.barWidth = 120;
        sizeTextInsideBar = 18;
      }

      if (this.packagesData.length > 3) {
        this.barWidth = 120;
        sizeTextInsideBar = 18;

        if (this.smartmeter) {
          this.barWidth = 90;
          sizeTextInsideBar = 14;
        }
      }
    }

    Chart.defaults.set('plugins.datalabels', {
      labels: {
        title: {
          font: {
            weight: 'bold',
            size: sizeTextInsideBar
          }
        }
      }
    });

    const ctx = document.getElementById('myChart');
    new Chart(ctx, {
      plugins: [ChartDataLabels],
      type: 'bar',
      data: {
        labels: this.label,
        datasets: [
          {
            data: this.consumptionData,
            backgroundColor: 'rgba(70, 180, 62, 1)',
            barThickness: this.barWidth,
            borderRadius: 8
          },
          {
            data: this.consumptionDataStacked,
            backgroundColor: 'rgba(70, 180, 62, 0.2)',
            barThickness: this.barWidth,
            borderRadius: 8,
            datalabels: {
              labels: {
                title: null
              }
            }
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            formatter: function(value, context) {
              if (value === 0) return '';
              value = Math.round(Number(String(value).replace(/,/g, '.')));
              value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
              if (context.dataset.data.length > 3) {
                return [value, ' kwh'];
              }
              return value + ' kwh';
            },
            color: '#FFFFFF'
          }
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: '#FFFFFF'
            },
            grid: {
              display: false,
              drawBorder: true,
              borderColor: '#071E7F',
              borderWidth: 2
            }
          },
          y: {
            stacked: true,
            suggestedMax: this.chartHeight,
            ticks: {
              callback: v => (v === 0 ? '' : ` ${this.getLabel(v)}`),
              font: {
                family: 'NeoSans',
                weight: 'normal'
              },
              padding: 5,
              color: '#071E7F'
            },
            grid: {
              borderDash: [3, 4],
              color: ctx => (ctx.tick.value === 0 ? '#fff' : '#071E7F'),
              lineWidth: 2,
              drawBorder: false
            }
          }
        }
      }
    });
  },

  methods: {
    getLabel(val) {
      if (window.outerWidth < 1400) {
        return [val, 'kwh'];
      }
      return val + ' kwh';
    },

    getChartData() {
      let fullSizePackage = [],
        restSizePackage = [];
      Object.entries(this.packagesData).forEach(item => {
        fullSizePackage.push(Number(item[1].rest_kwh_quantity));
        restSizePackage.push(
          item[1].kwh_quantity - Number(item[1].rest_kwh_quantity)
        );
      });

      const sizes = this.packagesData.map(object => {
        return object.kwh_quantity;
      });

      const maxPackageSize = Math.max(...sizes);

      this.label = new Array(Object.keys(this.packagesData).length).fill(
        'Value'
      );
      this.consumptionData = fullSizePackage;
      this.consumptionDataStacked = restSizePackage;
      this.chartHeight = maxPackageSize;
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
