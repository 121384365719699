<template>
  <div
    class="heading-col"
    :class="{ 'smart-meter_heading': meterInfo.smartmeter }"
  >
    <h1 class="color-primary-100 heading-mr">
      Guten Tag, <br />
      {{ meterInfo.user.first_name }} {{ meterInfo.user.last_name }}
    </h1>

    <p class="color-accent-300 text-subtitle">{{ text.subtitle }}</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { fieldsMR } from '@/placeholder/fieldsValidation';

export default {
  data: () => ({ fieldsMR }),

  computed: {
    ...mapState('user', ['meterInfo']),

    text() {
      return {
        subtitle: this.meterInfo.smartmeter
          ? 'Willkommen auf Ihrem Strompakete-Dashboard. Hier können Sie Zählerstände erfassen und den Verlauf Ihres Strompakets im Detail nachverfolgen.'
          : 'Hier können Sie Ihren Zählerstand erfassen.'
      };
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
