<template>
  <main id="auth-code" class="next-container">
    <input-reading-form
      v-if="shortMeterInfo.can_submit && !dashboardVisibility"
      :meterId="shortMeterInfo.meter_id"
      :showModal="showModal"
      :emptyDate="emptyDate"
      :disabled="isFormDisabled"
      :minDate="getDatepickerMinValue()"
      @changeReadingAtDate="readingAtDate = $event"
    />

    <input-reading-dashboard
      v-else-if="dashboardVisibility"
      :showModal="showModal"
      :emptyDate="emptyDate"
      @changeReadingAtDate="readingAtDate = $event"
    />

    <input-reading-modal
      :showModal="showModal"
      :readingAtDate="readingAtDate"
      :showModalFlag="showModalFlag"
    />
  </main>
</template>

<script>
import { fieldsMR } from '@/placeholder/fieldsValidation';
import { mapState } from 'vuex';
import InputReadingForm from '../../../components/next/input-reading-form.vue';
import InputReadingModal from '../../../components/next/input-reading-modal.vue';
import InputReadingDashboard from '../../../components/next/input-reading-dashboard.vue';
import { format, isToday } from 'date-fns';

export default {
  components: { InputReadingForm, InputReadingModal, InputReadingDashboard },

  data: () => ({
    fieldsMR,
    readingAtDate: null,
    showModalFlag: false,
    emptyDate: false,
    formErrors: false
  }),

  computed: {
    ...mapState('user', ['dashboardVisibility', 'shortMeterInfo']),

    isFormDisabled() {
      const lastReadingAt = this.shortMeterInfo.last_reading_at;
      const isErstmeldung = this.shortMeterInfo.last_reading_is_erstmeldung;

      if (!isErstmeldung) {
        return false;
      }

      return lastReadingAt ? isToday(new Date(lastReadingAt)) : false;
    }
  },

  methods: {
    getDatepickerMinValue() {
      const lastReadingAt = this.shortMeterInfo.last_reading_at;
      let firstAllowedDate = new Date();

      if (lastReadingAt) {
        const date = new Date(lastReadingAt);
        const nextDay = new Date(lastReadingAt);
        nextDay.setDate(nextDay.getDate() + 1);

        firstAllowedDate = this.shortMeterInfo.last_reading_is_erstmeldung
          ? nextDay
          : date;
      }

      return format(firstAllowedDate, 'yyyy-MM-dd');
    },

    compareMeterValueWithPreviousReading() {
      const lastMeterValue = this.shortMeterInfo.last_reading_value ?? 0;

      if (
        !this.hasMeterChangedAfterLastReading() &&
        Number(this.fieldsMR.meterValue.value) < lastMeterValue
      ) {
        this.fieldsMR.meterValue.valid = false;
        this.fieldsMR.meterValue.error =
          'Der Zählerstand muss größer als bei der letzten Meldung sein.';
        this.formErrors = true;
      }
    },

    handleEmptyMeterValue() {
      if (this.fieldsMR.meterValue.value === '') {
        this.fieldsMR.meterValue.valid = false;
        this.fieldsMR.meterValue.error = 'Der Zählerstand ist ein Pflichtfeld';
        this.formErrors = true;
      }
    },

    showModal() {
      this.formErrors = false;
      this.clearError('meterValue');
      this.compareMeterValueWithPreviousReading();
      this.handleEmptyMeterValue();

      if (this.readingAtDate === null) {
        this.emptyDate = true;
        this.formErrors = true;
      } else {
        this.emptyDate = false;
      }

      if (!this.formErrors) {
        this.showModalFlag = !this.showModalFlag;
      }
    },

    clearError(field) {
      this.fieldsMR[field].error = '';
      this.fieldsMR[field].valid = true;
    },

    hasMeterChangedAfterLastReading() {
      const lastChange = this.shortMeterInfo.meter_installation_at;
      const lastReading = this.shortMeterInfo.last_reading_at;

      return !lastChange || !lastReading
        ? false
        : +new Date(lastChange) > +new Date(lastReading);
    }
  },

  created() {
    this.$store.commit('user/SET_CURRENT_FLOW', 'mr_dashboard');
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
