<template>
  <div class="consumption-table">
    <div class="consumption-table_head">
      <div class="left-column">
        <span>Datum</span>
      </div>

      <div class="right-column">
        <span>Zählerstand/Aktion</span>
      </div>
    </div>

    <div class="consumption-table_body">
      <div v-for="(item, idx) in mappedData" :key="idx">
        <div class="consumption-table_body-row">
          <div class="left-column">
            <span>
              {{
                getFormattedDate(
                  item.type === 'reading' ? item.reading_at : item.date
                )
              }}
            </span>
          </div>

          <div class="right-column">
            <span v-if="item.type === 'reading'">
              Zählerstandsmeldung:

              <span class="right-column-value">
                <template v-if="item.is_estimated">(</template>

                {{ addDotToThousands(item.meter_value) }}

                <template v-if="item.is_estimated">)</template> kWh
              </span>
            </span>

            <span v-if="item.type === 'package'">
              Paketkauf: {{ item.name }} {{ item.name_size }},

              <span class="right-column-value">
                {{ addDotToThousands(item.kwh_quantity) }} kWh
              </span>
            </span>

            <span v-if="item.type === 'meter-change'">
              Zählertausch
              <br />
              ({{ item.meter_id }})
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const monthName = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember'
];

export default {
  props: {
    meterInfo: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  data() {
    return {
      mappedData: []
    };
  },

  methods: {
    createMeterInfoArray() {
      let type,
        lastItems = [],
        additionalData = [],
        date;
      const initial = { submitted_at: '2000-01-01' };

      const length = Object.keys(this.meterInfo.packages).length - 1;
      Object.values(this.meterInfo.packages).reduce((prev, curr, index) => {
        const datePackagePrev = new Date(prev.submitted_at);
        const datePackageCurr = new Date(curr.submitted_at);
        Object.entries(this.meterInfo.meter_changes).forEach(item => {
          const dateMeterChange = new Date(item[1].date);
          if (
            dateMeterChange > datePackagePrev &&
            dateMeterChange <= datePackageCurr
          ) {
            type = 'meter-change';
            additionalData.push({ ...item[1], type });
          }
          if (index === length && dateMeterChange > datePackageCurr) {
            type = 'meter-change';
            lastItems.push({ ...item[1], type });
          }
        });
        type = 'package';
        date = curr.submitted_at;
        additionalData.push({ ...curr, type, date });
        return { ...curr };
      }, initial);

      lastItems.forEach(item => {
        additionalData.push({ ...item });
      });

      return additionalData;
    },

    createFullDataArray() {
      let type,
        lastItems = [],
        additionalData = this.createMeterInfoArray();
      const initial = { reading_at: '2000-01-01' };
      const length = this.meterInfo.meter_readings.length - 1;

      this.mappedData = [];
      this.meterInfo.meter_readings.reduce((prev, curr, index) => {
        const dateReadingPrev = new Date(prev.reading_at);
        const dateReadingCurr = new Date(curr.reading_at);
        additionalData.forEach(item => {
          const datePackage = new Date(item.date);
          if (datePackage > dateReadingPrev && datePackage <= dateReadingCurr) {
            this.mappedData.push({ ...item });
          }
          if (index === length && datePackage > dateReadingCurr) {
            lastItems.push({ ...item });
          }
        });
        type = 'reading';
        this.mappedData.push({ ...curr, type });
        return { ...curr };
      }, initial);
      lastItems.forEach(item => {
        this.mappedData.push({ ...item });
      });

      this.mappedData.reverse();
    },

    getFormattedDate(timestamp) {
      const date = new Date(timestamp);
      return (
        date.getDate() +
        ' ' +
        monthName[date.getMonth()] +
        ' ' +
        date.getFullYear()
      );
    },

    addDotToThousands(val) {
      if (val) {
        val = Math.round(Number(String(val).replace(/,/g, '.')));
        val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return val;
    }
  },
  
  mounted() {
    this.createFullDataArray();
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
