<template>
  <section-base>
    <h1 class="color-primary-100 heading-mr heading-col">
      Bitte geben Sie Ihren Zählerstand ein
    </h1>

    <div class="heading-col meter-reading-number">
      <p class="color-accent-300">
        <span class="text-style-medium">Zählernummer:</span>
        {{ meterId }}
      </p>
    </div>

    <form @submit.prevent="showModal" class="meter-reading-form">
      <div class="form-grid form-grid-col">
        <div class="form-grid-item meter-reading-consumption">
          <div class="form-grid-item-input-block">
            <input-regular
              v-model="fieldsMR.meterValue.value"
              type="text"
              label="Zählerstand"
              id="meter_consumption_input"
              :showLabel="true"
              :validation="fieldsMR.meterValue"
              @press="onlyNumbersAndComma"
            />
          </div>

          <div
            class="form-grid-item-input-block date-picker-block"
            data-app
            :class="{ 'error-date': emptyDate }"
          >
            <div class="form-grid-item-input-block date-picker-block" data-app>
              <div class="form-grid-item-label ">
                <span
                  class="form-grid-item-title"
                  :class="{ 'error-label': emptyDate }"
                  >Ablesedatum</span
                >
              </div>

              <datepicker :min="minDate" @choose-date="getReadingAtDate" />

              <template v-if="emptyDate">
                <div class="error-wrapper">
                  <span class="error-message">
                    Dieses Feld muss angegeben werden
                  </span>
                </div>
              </template>
            </div>

            <div class="button-block">
              <button-regular type="submit" class="button-accent">
                Absenden
              </button-regular>
            </div>

            <a class="go-to-dashboard" @click="showDashboard">
              Jetzt keinen Zählerstand melden und weiter zum Dashboard
            </a>
          </div>
        </div>
      </div>
    </form>
  </section-base>
</template>

<script>
import { fieldsMR } from '@/placeholder/fieldsValidation';
import Datepicker from '@/components/next/datepicker/datepicker.vue';
import InputRegular from '@/components/next/input/input-regular.vue';
import ButtonRegular from '@/components/next/button-regular.vue';
import SectionBase from '@/components/next/section-base.vue';
import { mapState } from 'vuex';

export default {
  components: { Datepicker, InputRegular, ButtonRegular, SectionBase },

  props: ['showModal', 'emptyDate', 'minDate', 'meterId', 'disabled'],

  data: () => ({ fieldsMR }),

  computed: {
    ...mapState('user', ['accessToken'])
  },

  methods: {
    getReadingAtDate(reading) {
      this.$emit('changeReadingAtDate', reading);
    },

    onlyNumbersAndComma(e) {
      e = e ? e : window.event;
      var charCode = String.fromCharCode(e.which ? e.which : e.keyCode);
      let val = document.getElementById('meter_consumption_input').value;
      if (
        !/^[0-9,\b]+$/.test(charCode) ||
        (val.includes(',') && charCode === ',')
      ) {
        e.preventDefault();
      }
    },

    showDashboard() {
      if (!this.accessToken) {
        this.$router.push({
          name: 'AuthUser',
          params: { uuid: this.$route.params.uuid }
        });

        return;
      }

      this.$store.commit('user/SET_DASHBOARD_VISIBILITY', true);
    }
  },

  created() {
    if (this.disabled) {
      this.showDashboard();
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
