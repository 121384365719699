<template>
  <div class="chart-section">
    <div class="chart-title">
      <div>
        <h4 class="color-accent-300">Durchschnittlicher Verbrauch</h4>
      </div>
      <div>
        <button
          class="prev-year"
          type="button"
          :class="{ 'hide-arrow': hideLeftArrow }"
          @click="handleYearChange('prev')"
        ></button>

        <button
          class="next-year"
          type="button"
          :class="{ 'hide-arrow': hideRightArrow }"
          @click="handleYearChange('next')"
        ></button>
      </div>
    </div>

    <div class="chart-block">
      <div class="chart-container">
        <axis
          :containerHeight="chartContainerHeight"
          :yAxisHeight="getYAxisHeight"
          :numberOfHorizontalLines="numberOfHorizontalLines"
        />

        <div class="chart-wrap" :style="`height: ${chartContainerHeight}px`">
          <average-line :averageLineHeight="averageLineHeight">
            durchschnit
          </average-line>

          <div
            class="chart"
            :style="{ gridTemplateColumns: `repeat(${daysInYear}, 1fr)` }"
          >
            <div
              v-for="reading in selectedYearReadings"
              v-tooltip="getTooltipContent(reading)"
              :key="+reading.startDate"
              class="bar"
              :style="{
                height: `${getBarHeight(reading)}%`,
                gridColumn: getReadinColSpan(reading)
              }"
            ></div>
          </div>

          <horizontal-label :year="displayedYear" :daysInYear="daysInYear" />

          <div class="x-axis-title">
            <p>
              Datum des <br />
              Zählerstands&#8209;<br />&#8209;erfassung
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalLabel from '@/components/next/chart/horizontal-label.vue';
import AverageLine from '@/components/next/chart/average-line.vue';
import Axis from '@/components/next/chart/axis.vue';
import { mapState } from 'vuex';
import {
  startOfDay,
  startOfYear,
  endOfYear,
  getDaysInYear,
  addDays,
  format
} from 'date-fns';

export default {
  components: { Axis, HorizontalLabel, AverageLine },

  data() {
    return {
      chartContainerHeight: 300,
      groupedData: [],
      displayedYear: 0
    };
  },

  directives: {
    tooltip: {
      inserted(el, binding) {
        if (!binding.value) return;

        el.classList.add('chart-tooltip');
        el.setAttribute('data-tooltip', binding.value);
      }
    }
  },

  computed: {
    ...mapState('user', ['meterInfo']),

    selectedYearReadings() {
      return this.groupedData[this.displayedYear] ?? [];
    },

    daysInYear() {
      const yearDate = new Date(`${this.displayedYear}`);

      return getDaysInYear(yearDate);
    },

    getYAxisHeight() {
      const consumptions = this.getAllConsumptions();
      const highestValue = consumptions.length
        ? Number(Math.max(...consumptions))
        : 100;

      return Math.ceil(highestValue / 100) * 100;
    },

    numberOfHorizontalLines() {
      const yAxisHeight = this.getYAxisHeight;

      if (yAxisHeight > 300) {
        const value = yAxisHeight / 100;

        return value < 13 ? value : 12;
      }

      return yAxisHeight > 200 ? 6 : 4;
    },

    averageLineHeight() {
      const averageConsumption =
        this.meterInfo?.calculations?.average_yearly_consumption?.[
          this.displayedYear
        ]?.average_kwh_per_month ?? 0;

      return (averageConsumption * 100) / this.getYAxisHeight;
    },

    hideLeftArrow() {
      const firstYear = Object.keys(this.groupedData).shift();

      return `${this.displayedYear}` === firstYear;
    },

    hideRightArrow() {
      const lastYear = Object.keys(this.groupedData).pop();

      return `${this.displayedYear}` === lastYear;
    }
  },

  methods: {
    getReadinColSpan(reading) {
      const daysDiff =
        this.datediff(
          startOfDay(new Date(reading.endDate)),
          startOfDay(new Date(reading.startDate))
        ) + 1;

      return `span ${daysDiff} / span ${daysDiff}`;
    },

    getTooltipContent(reading) {
      const from = format(reading.tooltipStart, 'dd.MM.yyyy');
      const to = format(reading.tooltipEnd, 'dd.MM.yyyy');
      const consumption = `${Math.trunc(reading.value)} kWh`;
      return `${from} - ${to}\n${consumption}`;
    },

    datediff(second, first) {
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    },

    getAllConsumptions() {
      return this.selectedYearReadings.map(reading => reading.value).flat();
    },

    getBarHeight(reading) {
      const consumption = this.getConsumption(reading);

      return this.getYAxisHeight && (consumption * 100) / this.getYAxisHeight;
    },

    getConsumption(reading) {
      const readingConsumption = Number(reading.value);

      return readingConsumption > 0 ? readingConsumption : 0;
    },

    handleYearChange(type) {
      const newYearIndex = type === 'next' ? 1 : -1;
      const newYear = this.displayedYear + newYearIndex;

      if (!this.groupedData[newYear]) {
        return;
      }

      this.displayedYear = newYear;
    },

    showLatestYear() {
      const years = Object.keys(this.groupedData);

      if (years.length) {
        this.displayedYear = Math.max(...years);
      }
    },

    handleLastReadInYear(readingDate, nextReading) {
      const year = readingDate.getFullYear();
      const isLastDayInYear =
        readingDate.getMonth() === 11 && readingDate.getDate() === 31;

      if (
        !isLastDayInYear &&
        nextReading &&
        new Date(nextReading.day).getFullYear() > year
      ) {
        this.groupedData[year].push({
          startDate: addDays(readingDate, 1),
          endDate: endOfYear(readingDate),
          tooltipStart: addDays(readingDate, 1),
          tooltipEnd: new Date(nextReading.day),
          value: nextReading.value
        });
      }
    },

    getStartDate(readingDate, prevReading) {
      return prevReading
        ? addDays(new Date(prevReading.day), 1)
        : startOfYear(readingDate);
    },

    groupDataByYear() {
      this.meterInfo.consumptions?.forEach((reading, index) => {
        const readingDate = new Date(reading.day);
        const year = readingDate.getFullYear();
        const prevReading = this.meterInfo.consumptions[index - 1];
        const nextReading = this.meterInfo.consumptions[index + 1];

        let startDate = this.getStartDate(readingDate, prevReading);

        if (!this.groupedData[year]) {
          this.groupedData[year] = [];

          startDate = startOfYear(readingDate);
        }

        this.groupedData[year].push({
          tooltipStart: this.getStartDate(readingDate, prevReading),
          tooltipEnd: readingDate,
          startDate: startDate,
          endDate: readingDate,
          value: reading.value
        });

        this.handleLastReadInYear(readingDate, nextReading);
      });
    }
  },

  mounted() {
    this.groupDataByYear();
    this.showLatestYear();
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
