<template>
  <div class="meter-reading_form-box" :class="{ 'disabled-form': isDisabled }">
    <h4>Zählerstandserfassung</h4>

    <p>
      <span class="text-style-medium">Zählernummer:</span>
      {{ meterInfo.current_meter_id }}
    </p>

    <p>Bitte geben Sie Ihren Zählerstand ein:</p>

    <form class="meter-reading-form" @submit.prevent="showModal">
      <div class="form-grid form-grid-col">
        <div class="form-grid-item meter-reading-consumption">
          <div class="form-grid-item-input-block">
            <input-regular
              v-model="fieldsMR.meterValue.value"
              :isDisabled="isDisabled"
              type="text"
              label="Zählerstand"
              id="meter_consumption_input"
              :showLabel="true"
              :validation="fieldsMR.meterValue"
              @press="onlyNumbersAndComma"
            />
          </div>

          <div
            class="form-grid-item-input-block date-picker-block"
            data-app
            :class="{ 'error-date': emptyDate }"
          >
            <div class="form-grid-item-input-block date-picker-block" data-app>
              <div class="form-grid-item-label ">
                <span
                  class="form-grid-item-title"
                  :class="{ 'error-label': emptyDate }"
                  >Ablesedatum</span
                >
              </div>

              <datepicker
                :min="getDatepickerMinValue()"
                @choose-date="getReadingAtDate"
              />

              <template v-if="emptyDate">
                <div class="error-wrapper">
                  <span class="error-message">
                    Dieses Feld muss angegeben werden
                  </span>
                </div>
              </template>
            </div>

            <div class="button-block">
              <button-regular
                type="submit"
                class="button-accent"
                :disabled="isDisabled"
              >
                Absenden
              </button-regular>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { fieldsMR } from '@/placeholder/fieldsValidation';
import Datepicker from '@/components/next/datepicker/datepicker.vue';
import InputRegular from '@/components/next/input/input-regular.vue';
import ButtonRegular from '@/components/next/button-regular.vue';
import { format, isToday } from 'date-fns';

export default {
  components: { Datepicker, InputRegular, ButtonRegular },

  props: ['showModal', 'emptyDate', 'minDate'],

  data: () => ({ fieldsMR }),

  computed: {
    ...mapState('user', ['meterInfo']),

    isDisabled() {
      const lastReading = this.getLastReading();

      if (lastReading.month !== 'Erstmeldung') {
        return false;
      }
      
      return lastReading.reading_at
        ? isToday(new Date(lastReading.reading_at))
        : false;
    }
  },

  methods: {
    getReadingAtDate(reading) {
      this.$emit('changeReadingAtDate', reading);
    },

    onlyNumbersAndComma(e) {
      e = e ? e : window.event;
      const charCode = String.fromCharCode(e.which ? e.which : e.keyCode);
      const val = document.getElementById('meter_consumption_input').value;

      if (
        !/^[0-9,\b]+$/.test(charCode) ||
        (val.includes(',') && charCode === ',')
      ) {
        e.preventDefault();
      }
    },

    getLastReading() {
      return this.meterInfo?.meter_readings
        ?.filter(e => e.reading_at)
        .sort((a, b) => a.reading_at.localeCompare(b.reading_at))
        .pop();
    },

    getFirstAllowedDate() {
      const lastReading = this.getLastReading();
      if (!lastReading.reading_at) {
        return undefined;
      }

      const date = new Date(lastReading.reading_at);
      const nextDay = new Date(lastReading.reading_at);
      nextDay.setDate(nextDay.getDate() + 1);

      return lastReading.month === 'Erstmeldung' ? nextDay : date;
    },

    getDatepickerMinValue() {
      const firstAllowedDate = this.getFirstAllowedDate() ?? new Date();

      return format(firstAllowedDate, 'yyyy-MM-dd');
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
