<template>
  <section-base class="custom-mobile">
    <dashboard-heading />

    <div
      class="meter-reading_section"
      :class="{ 'smart-meter_properties': meterInfo.smartmeter }"
    >
      <div class="meter-reading_columns">
        <div class="meter-reading_left-column">
          <dashboard-form
            v-if="!meterInfo.smartmeter"
            :showModal="showModal"
            :emptyDate="emptyDate"
            @changeReadingAtDate="$emit('changeReadingAtDate', $event)"
          />

          <smartmeter-info v-if="meterInfo.smartmeter" />

          <div :class="{ 'smart-meter_table': meterInfo.smartmeter }">
            <table-consumption :meterInfo="meterInfo" />
          </div>

          <p class="color-accent-300 smart-meter-tip">
            *Werte in Klammern sind geschätzt
          </p>
        </div>

        <div v-if="!meterInfo.smartmeter" class="meter-reading_right-column">
          <info-list />

          <div class="chart-packages_section">
            <chart-packages :packagesData="meterInfo.packages"></chart-packages>
          </div>
        </div>

        <div v-else class="meter-reading_right-column">
          <power-consumption />
        </div>
      </div>

      <div v-if="!meterInfo.smartmeter" class="chart-consumption_section">
        <chart />
      </div>
    </div>
  </section-base>
</template>

<script>
import SectionBase from '@/components/next/section-base.vue';
import Chart from '@/components/next/chart/chart.vue';
import ChartPackages from '@/components/next/chart/chart-packages.vue';
import TableConsumption from '@/components/next/table-consumption.vue';
import InfoList from '@/components/next/input-reading-dashboard/info-list.vue';
import PowerConsumption from '@/components/next/input-reading-dashboard/power-consumption.vue';
import DashboardForm from '@/components/next/input-reading-dashboard/dashboard-form.vue';
import SmartmeterInfo from '@/components/next/input-reading-dashboard/smartmeter-info.vue';
import DashboardHeading from '@/components/next/input-reading-dashboard/dashboard-heading.vue';
import { mapState } from 'vuex';

export default {
  components: {
    SectionBase,
    TableConsumption,
    InfoList,
    SmartmeterInfo,
    DashboardForm,
    PowerConsumption,
    DashboardHeading,
    ChartPackages,
    Chart
  },

  props: ['showModal', 'emptyDate'],

  computed: {
    ...mapState('user', ['meterInfo'])
  },

  beforeCreate() {
    this.$store.dispatch('user/GET_METER_READING_DATA', {
      uuid: this.$route.params.uuid,
      push: false
    });
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
