<template>
  <div
    class="x-label"
    :style="{ gridTemplateColumns: `repeat(${daysInYear}, 1fr)` }"
  >
    <li
      v-for="(numberOfDays, month) in months"
      :key="month"
      :style="{
        gridColumn: `span ${numberOfDays} / span ${numberOfDays}`
      }"
    >
      <span>{{ formatDate(month) }}</span>
    </li>
  </div>
</template>

<script>
export default {
  props: {
    year: { type: Number },
    daysInYear: { type: Number }
  },

  computed: {
    months() {
      return {
        1: 31,
        2: this.daysInYear === 366 ? 29 : 28,
        3: 31,
        4: 30,
        5: 31,
        6: 30,
        7: 31,
        8: 31,
        9: 30,
        10: 31,
        11: 30,
        12: 31
      };
    }
  },

  methods: {
    getMonthColSpan() {},
    formatDate(month) {
      const formattedMonth = String(month).padStart(2, '0');

      return `01.${formattedMonth}.${this.year}`;
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
